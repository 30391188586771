
<template>
    <footer>
        <div class="footer-content">
            <div class="footer-col1">
                <div class="social-links">
                    <a v-for="(link, index) in links" :key="index" :href="link.url" target="_blank">
                        {{ link.text }}
                    </a>
                </div>

            </div>
            <div class="footer-col2">
                <div class="credits">
                    <p>{{ credits }}</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            links: [
                { text: 'Instagram', url: 'https://www.instagram.com/luizaboladesign/' },
                { text: 'Behance', url: 'https://www.behance.net/luizabola' }
            ],
            credits: '© 2023 Luiza Bola Estúdio'
        }
    }
}
</script>

<style lang="scss">
#app {
    /*height: 93vh;*/
    display: flex;
    flex-wrap: wrap;

    & footer {
        align-self: self-end;
        width: 100%;
        padding: 0px;
        margin: 0 auto;
        max-width: 98vw;
        font-size: 1.2vw;
        font-family: var(--eb);

        & .footer-content {
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: unset;

            @media(max-width:1110px) {
                font-size: 15px;
            }

            & .footer-col1 {
                display: flex;
                align-self: center;

                & .social-links {
                    gap: 5.5em;
                    display: flex;

                    @media(max-width:820px) {
                        gap: 2em;
                    }
                }
            }
        }
    }
}
</style>