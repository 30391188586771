<template>
    <div class="click-solicite">
        <a @click="showProjs"> Solicite um orçamento</a>
    </div>
    <div id="show-projs">
        <div v-for="(proj, index) in projs" :key="index" :class=proj.classes>
            <a target="_blank" :href="proj.url">
                {{ proj.text }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            projs:[
                {classes:'id-visual', text:'identidade visual & embalagem', url:'https://form.jotform.com/231294502911652'},
                {classes:'website', text:'website', url:'https://form.jotform.com/231244536746660'}
            ]
        }
    },
    
    setup() {
        function showProjs() {
            let show = document.getElementById('show-projs');
            if (show.classList.contains('visible')) {
                show.classList.remove('visible')
            } else {
                show.classList.add('visible')
            }
        }
        return {
            showProjs
        }
    }
}
</script>

<style>
.click-solicite,
#show-projs {
    font-family: var(--com) !important;
    line-height: 2em;
    text-transform: uppercase;
}


#show-projs {
    opacity: 0;
    transition: var(--transition);
    pointer-events: none;
}

.visible {
    opacity: 1 !important;
    pointer-events: initial !important;

}
</style>