<template>
  <div class="cursor">
    <div class="cursor__ball cursor__ball--big ">
      <svg height="30" width="30">
        <circle cx="10" cy="10" r="7" stroke-width="0"></circle>
      </svg>
    </div>
  </div>

  <div class="loading-content" v-if="isLoading">
    <Loading />
  </div>

  <VamosCriar v-else />
</template>

<script>
import VamosCriar from './components/VamosCriar.vue'
import Loading from './components/loading/LoadingView.vue'
import { ref, onMounted } from 'vue'

import { TweenMax } from 'gsap'

export default {
  components: {
    VamosCriar,
    Loading
  },
  setup() {


    let isLoading = ref(true)

    onMounted(() => {

      const $bigBall = document.querySelector('.cursor__ball--big');
      const cursorAppear = document.querySelector('.cursor');
      const $hoverables = document.querySelectorAll('.hoverable');

      // Listeners
      document.body.addEventListener('mousemove', onMouseMove);
      for (let i = 0; i < $hoverables.length; i++) {
        $hoverables[i].addEventListener('mouseenter', onMouseHover);
        $hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
      }

      // Move the cursor
      function onMouseMove(e) {
        TweenMax.to($bigBall, .2, {
          x: e.pageX - 10,
          y: e.pageY - 10
        })
      }
      function isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
      }

      setTimeout(() => {
        if (isTouchDevice()) {
          cursorAppear.style.display = 'none';
        } else {

          cursorAppear.style.display = "initial"
        }

      }, 1000);
      setTimeout(() => {
        cursorAppear.style.opacity = 1

      }, 4000);
      // Hover an element
      function onMouseHover() {
        TweenMax.to($bigBall, .3, {
          scale: 4
        })
      }
      function onMouseHoverOut() {
        TweenMax.to($bigBall, .3, {
          scale: 1
        })
      }

      window.onload = () => {
        setTimeout(() => {

          const loading = document.querySelector('.loading-content');
          loading.classList.add('slide-up')

        }, 3500);
        setTimeout(() => {
          isLoading.value = false

        }, 4000);
      }

    })

    return {

      isLoading

    }
  }
}
</script>
<style lang="scss">
body,
html,
#app {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.content {
  display: contents;
}

a {
  cursor: none !important;
}

body {
  cursor: none;
  color: #FCF9F5;
  background-color: #000;
  transition: var(--transition) //height: max-content
}

h1,
h2,
h3 {
  margin: 0;
}

a {
  cursor: pointer;
  transition: var(--transition);
  color: #FCF9F5;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

#app {
  min-height: 250px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.loading-content {
  z-index: 9999;
  transition: var(--transition);
  width: 100vw;
}

.slide-up {
  transform: translateY(-100%);
}


//CURSOR

.cursor {
  display: none;
  opacity: 0;
  pointer-events: none;
  transition: 1s;

  &__ball {
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;

    circle {
      fill: #f7f8fa;
    }
  }
}
</style>
