<template>
    <h1 id="title1">
        Vamos criar um
    </h1>
    <h2>projeto <em>incrível?</em>
    </h2>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>

<style></style>
