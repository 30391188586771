<template>
  <div data-scroll-container class="main-content">

    <section id="section0" @load='fadeIn' data-scroll-section>
      <a href="#section2" data-scroll-to="#">
        <img id="seta" alt="seta" src="seta.png" />
      </a>
    </section>

    <section id="section1" data-scroll-section>
      <div class="section-content" data-scroll data-scroll-repeat data-scroll-class="section-visible">

        <div class="main-title" data-scroll data-scroll-speed="-4" data-scroll-sticky data-scroll-target="#section1">
          <TituloView />
        </div>

        <div class="orcamento" data-scroll data-scroll-sticky data-scroll-target="#section1">
          <OrcamentoView />
        </div>

      </div>
    </section>

    <section data-scroll-section id="section2">

      <div class="section-content" data-scroll data-scroll-repeat data-scroll-class="section-visible">
        <MyFooter />
      </div>

    </section>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import OrcamentoView from '../components/orcamento/OrcamentoView.vue'
import TituloView from '../components/titulo/TituloView.vue'
import MyFooter from '../components/footer/MyFooter.vue'
import LocomotiveScroll from 'locomotive-scroll'

export default {
  components: {
    TituloView,
    OrcamentoView,
    MyFooter,
  },

  setup() {

    function fadeIn() {
      const content = document.getElementById('section0')
      content.style.opacity = 1;
    }

    function initLocomotiveScroll() {
      const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        multiplier: 3,
        touchMultiplier: 3,
        reloadOnContextChange: true,
        tablet: {
          smooth: true,
          breakpoint: 0,
        },
        smoothMobile: true
      });
    }
    onMounted(() => {
      initLocomotiveScroll()
    })

    function resetLocomotiveScroll() {
      scroll.destroy;
      initLocomotiveScroll();
      scroll.refresh
    }
    window.addEventListener('resize', resetLocomotiveScroll);

    const mostraConteudo = ref(false)



    return {
      mostraConteudo
    }
  }
}
</script>

<style lang="scss">
@import 'locomotive-scroll';
@import 'root';


.c-scrollbar {
  display: none;
}

section {
  height: 100vh;
  opacity: 0;
  transition: opacity 1s;
}

.section-content {
  opacity: 0;
  transition: 1s;
}



.main-content {
  margin: 0 auto;
  width: 100%;

  & .main-content h1,
  .main-content h2 {
    min-width: 310px;
    font-size: 40px !important;
  }

  & #section0 {
    transition: opacity 0.5s ease forwards;
    height: 110vh;

    & img {
      padding-top: 40vh;
      animation: bouncer ease-out 1s infinite;
      filter: invert(100%);
    }

    @keyframes bouncer {
      0% {
        transform: translateY(0px);
        opacity: 0;
      }

      50% {
        transform: translateY(30px);
        opacity: 1;
      }

      100% {
        transform: translateY(30px);
        opacity: 0;
      }
    }
  }

  & #section1 {
    height: 100vh;

    & .main-title {
      & h1 {
        font-family: var(--display);
        font-weight: 300;
        padding-top: 30vh;
        font-size: var(--title-size);
      }

      & h2 {
        font-family: var(--romie);
        font-weight: 200;
        font-size: var(--title-size);

      }

    }

    & .main-title,
    .main-title,
    .orcamento {
      font-size: 1.1vw;
      letter-spacing: 1px;
      margin-top: 1.4vw;
    }

  }

  & #section2 {
    height: 15vh;
  }

}


.section-visible {
  animation: fadeSec 1s ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeSec {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media(max-width:1110px) {
  .orcamento {
    font-size: 16px !important;
  }
}

@media(max-width:820px) {
  .orcamento {
    font-size: 15px !important;
    margin-top: 1.5vh !important;
  }

  .footer-content {
    font-size: 19px;
    display: flex;
  }

  .footer-col1 {
    width: 100%;
    margin: 0 auto;
  }

  .footer-col2 {
    width: 100%;
  }

  .social-links {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    gap: 15px;
  }
}</style>
